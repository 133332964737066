import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import SidebarMenu from 'components/SidebarMenu'
import PageSection from 'components/PageSection'

import s from './contact.module.css'

const Contact = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfContact: { contact, stockists, pressEnquiries, generalEnquiries },
  } = data.wordpressPage

  const items = [contact, stockists, pressEnquiries, generalEnquiries]

  return (
    <Layout backgroundColor="#F2F2F2">
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <div className={s.container}>
        <SidebarMenu activeLink="Contact Us" />
        <div className={s.items}>
          {items.map((item) => (
            <PageSection key={item.title} data={item} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

Contact.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfContact: PropTypes.shape({
        contact: PropTypes.object,
        stockists: PropTypes.object,
        pressEnquiries: PropTypes.object,
        generalEnquiries: PropTypes.object,
      }),
    }),
  }),
}
Contact.defaultProps = {
  data: {},
}

export default Contact

export const pageQuery = graphql`
  query Contact {
    wordpressPage(uri: { eq: "/contact/" }) {
      seo {
        title
        description
      }
      acfContact {
        contact {
          title
          description
          buttons {
            link {
              target
              title
              url
            }
          }
        }
        stockists {
          title
          description
          buttons {
            link {
              target
              title
              url
            }
          }
        }
        pressEnquiries {
          title
          description
          buttons {
            link {
              target
              title
              url
            }
          }
        }
        generalEnquiries {
          title
          description
          buttons {
            link {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`
